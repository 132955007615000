import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const ContactListItem = ({content}) => {
    let phone = content.phone
        ? content.phone.trim()
        : null

    phone = (phone && phone.length === 10)
        ? '+33' + phone.substring(1)
        : phone

    return (
        <div className="card contact-list-item">
            {(content.image && content.relationships.image) && (
                <div className="card-image">
                    <GatsbyImage
                        image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                        alt={content.title} />
                </div>
            )}
            <div className="card-content">
                <div className="media">
                    <div className="media-content">
                        <p className="title is-4">{content.title}</p>

                        {content.address && (
                            <div className="contact-address">
                                <span className="content" dangerouslySetInnerHTML={{__html: content.address.value}}/>
                            </div>
                        )}

                        {content.contactName && (
                            <p className="contact-name">
                                <span className="icon">
                                    <i className="fas fa-user"></i>
                                </span>
                                <span className="icon-explain">{content.contactName}</span>
                            </p>
                        )}
                    </div>
                </div>
                {content.body && (
                    <small className="content" dangerouslySetInnerHTML={{__html: content.body.value}}/>
                )}
            </div>
            <footer className="card-footer">
                {(phone || content.email || content.website) && (
                    <div className="buttons">
                        {phone && (
                            <a href={`tel:${phone}`} title={content.phone} className="button is-primary is-rounded">
                                <span className="icon">
                                    <i className="fas fa-phone"></i>
                                </span>
                                <span className="icon-explain">Appeler</span>
                            </a>
                        )}
                        {content.email && (
                            <a href={`mailto:${content.email}`} title={content.email} className="button is-primary is-rounded">
                                <span className="icon">
                                    <i className="fas fa-envelope"></i>
                                </span>
                                <span className="icon-explain">Email</span>
                            </a>
                        )}
                        {content.website && (
                            <a href={content.website.uri} title={content.website.uri} className="button is-primary is-rounded">
                                <span className="icon">
                                    <i className="fas fa-globe"></i>
                                </span>
                                <span className="icon-explain">{content.website.title ? content.website.title : "Site internet"}</span>
                            </a>
                        )}
                    </div>
                )}
            </footer>
        </div>
    )
}

ContactListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default ContactListItem;
