import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import Title from "../components/Title";
import ContactListItem from "../components/ListItems/ContactListItem";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const TypesContactList = ({ pageContext, data, location }) => {
  const {
    breadcrumb: {crumbs},
    currentPage,
    numPageByTag,
    basePath
  } = pageContext

  return (
    <Layout>
      <Metas title={pageContext.name} />
      <section className="section page-breadcrumb is-small has-background-light">
        <div className="container">
            <CustomBreadcrumb crumbs={crumbs}/>
        </div>
      </section>
      <section className="section page-content">
        <div className="container is-max-desktop">
          <Title title={pageContext.name} className="has-text-centered"/>

          <div className="columns is-multiline">
            {data.contacts.nodes.map((node, index) => (
                <div key={index} className="column is-6">
                    <ContactListItem content={node} />
                </div>
            ))}
          </div>

          <Pagination
            path={`${location.pathname}/`}
            currentPage={currentPage}
            numPages={numPageByTag}
            basePath={basePath}
          />
        </div>
      </section>
    </Layout>
  )
}

export default TypesContactList

export const query = graphql`
    query contactTypesListQuery($tid: Int!, $skip: Int!, $limit: Int!) {
        contacts: allNodeContact(
            filter: {
                status: {eq: true},
                relationships: {
                    field_type: {
                        drupal_internal__tid: {eq: $tid}
                    }
                }
            },
            sort: { fields: title, order: ASC },
            limit: $limit,
            skip: $skip
        ) {
            nodes {
                id
                title
                address: field_adresse {
                   value
                }
                contactName: field_contact
                image: field_image {
                    alt
                    width
                    height
                }
                body {
                    value
                }
                phone: field_telephone
                email: field_email
                website: field_website {
                    uri
                    title
                }
                relationships {
                    type: field_type {
                        name
                        path {
                            alias
                        }
                    }
                    image: field_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 555
                                    height: 312
                                    quality: 100
                                    transformOptions: {cropFocus: CENTER}
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                }
            }
        }
    }`
